import { FC, PropsWithChildren } from "react";
import { LayoutContextProvider } from "./LayoutProvider";
import { ThemeContextProvider } from "./ThemeProvider";
export const UiProvider: FC<PropsWithChildren> = ({ children }) => {
	return (
		<LayoutContextProvider>
			<ThemeContextProvider>{children}</ThemeContextProvider>
		</LayoutContextProvider>
	);
};
