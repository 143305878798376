import { useRouter } from "next/router";
import Script from "next/script";
import { FC, PropsWithChildren, useEffect } from "react";
import { pageview } from "./analytics";
interface AnalyticsContainerProps {
	analyticsId: string;
}
export const AnalyticsContainer: FC<PropsWithChildren<AnalyticsContainerProps>> = ({ children, analyticsId }) => {
	const router = useRouter();
	useEffect(() => {
		if (process.env.NODE_ENV === "production") console.log = function () {};
	}, []);
	useEffect(() => {
		const handleRouteChange = (url) => {
			pageview(url);
		};
		router.events.on("routeChangeComplete", handleRouteChange);
		return () => {
			router.events.off("routeChangeComplete", handleRouteChange);
		};
	}, [router.events]);
	return (
		<>
			<Script id="google-analytics" strategy="afterInteractive">
				{`window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag('js', new Date());gtag('config', '${analyticsId}', {page_path: window.location.pathname,});`}
			</Script>
			{children}
		</>
	);
};
