import { AnalyticsContainer } from "analytics";
import { SeoConfig } from "config/seoConfig";
import { HeadGlobal } from "global/Head.global";
import { ScriptsGlobal } from "global/Scripts.global";
import { DefaultSeo } from "next-seo";
import type { AppProps } from "next/app";

import { NextPage } from "next";
import { ReactElement, ReactNode } from "react";
import { UiProvider } from "ui/src/providers/UiProvider";
import "ui/src/styles/styles.scss";
export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
	getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
	Component: NextPageWithLayout;
};
function MyApp({ Component, pageProps }: AppPropsWithLayout) {
	const NextComponent = Component as any;
	const getLayout = Component.getLayout ?? ((page) => page);

	if (process.env.NODE_ENV === "production") {
		console.log = function () {};
	}
	return (
		<>
			<AnalyticsContainer analyticsId={process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}>
				<UiProvider>
					{process.env.NODE_ENV !== "development" && <ScriptsGlobal />}
					<DefaultSeo {...SeoConfig} />
					{getLayout(<NextComponent {...pageProps} />)}
					<HeadGlobal />
				</UiProvider>
			</AnalyticsContainer>
		</>
	);
}
export default MyApp;
